.cCC{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.formCC{
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);  text-align: center;
  width: 900px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
}
.h2 {
  color: #e8e5e5;
}
.valueInput{
  display: flex;
  flex-direction: column;
}

.input {
  outline: none;
  border-radius: 5px;
  padding: 1em;
  border: 3px solid rgba(52, 51, 51, 0.61);
  transition: 200ms ease-in-out;
}

.input:focus {
  background-color: white;
  transform: scale(1.05);
  box-shadow: 13px 13px 100px #969696,-13px -13px 100px #ffffff;
}


.btn-donate {
  padding: 0.9em 1.4em;
  min-width: 120px;
  min-height: 44px;
  font-size: var(--size, 1rem);
  font-family: "cario", system-ui, sans-serif;
  font-weight: 500;
  transition: 0.8s;
  background-size: 280% auto;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);  text-align: center;
  color: #030303;

}

.btn-donate:hover {
  background-position: right top;
}

.btn-donate:is(:focus, :focus-within,:active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}

.flags{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
}

.from, .to {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-between;
}

select{
  background-color: transparent;
  border: none;
}

.boxCC{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 2px solid black;
  padding: 6px;
}

.switch{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

ion-icon{
  font-size: 24px;
}

.oneItem{
  justify-content: center;
  align-items: center;
  gap: 30px;
  /* border: 2px solid rgba(0, 0, 0, 0.742); */
  border-radius: 20px;
  padding: 4px 10px;
}

.oneItem img{
  max-width: 70px;
  border-radius: 50%;
}

.oneItem h1{
  padding: 5px;
}

#img1 img{
  border-radius: 30px;
}

@media only screen and (max-width: 600px) {
  .formCC{
    height: 600px;
  }
  .mostCountries{
    flex-direction: column;
    gap: 10px;
    margin-bottom: 65px;
  }
  .oneItem{
    height: 65px;
    width: 266px;
    flex-direction: row;
    gap: 20px;
  }
  .oneItem img{
    max-width: 40px;
    border-radius: 50%;
    height: 40px;
  }
  #resualt{
    font-size: 24px;
  }

  .from, .to {
    display: flex;
    flex-direction: column;
    width: 132px;
    justify-content: space-between;
  }
}

