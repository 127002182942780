.wrapper{
  width: 600px;
  background: #fff;
  border-radius: 10px;  
}
.wrapper h1{
  font-size: 25px;
  font-weight: 500;
  padding: 20px 25px;
}
.wrapper .content{
  margin: 25px 25px 35px;
}
.content .inputss{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.inputss input{
  height: 57px;
  width: 56px;
  margin: 4px;
  font-size: 24px;
  font-weight: 500;
  color: #2b9a34;
  text-align: center;
  border-radius: 5px;
  background: none;
  pointer-events: none;
  text-transform: uppercase;
  border: 1px solid #B5B5B5;
}
.typing-input {
  opacity: 0;
  z-index: -999;
  position: absolute;
  pointer-events: none;
}
.inputss input:first-child{
  margin-left: 0px;
}
.content .details{
  margin: 20px 0 25px;
}
.details p{
  font-size: 19px;
  margin-bottom: 10px;
}
.content .reset-btn{
  width: 100%;
  border: none;
  cursor: pointer;
  color: #fff;
  outline: none;
  padding: 15px 0;
  font-size: 17px;
  border-radius: 5px;
  background: #be4e93;
  transition: all 0.3s ease;
}


@media screen and (max-width: 460px) {
  .wrapper {
    width: 100%;
  }
  .wrapper h1{
    font-size: 22px;
    padding: 16px 20px;
  }
  .wrapper .content{
    margin: 25px 20px 35px;
  }
  .inputss input{
    height: 41px;
    width: 40px;
    margin: 3px;
    font-size: 22px;
  }
  .details p{
    font-size: 17px;
  }
  .content .reset-btn{
    padding: 14px 0;
    font-size: 16px;
  }
}