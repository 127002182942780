.button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #16a34a00;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 60px;
  line-height: 3em;
  padding: 0 1em;
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 60px;
  border: 1px solid red;
}

.button:hover {
  background-color: #17ac4e;
}

.details-modal {
  background: #63b0c5;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  left: 50%;
  max-width: 90%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 9999999999999;
}
.details-modal .details-modal-close {
  align-items: center;
  color: #111827;
  display: flex;
  height: 4.5em;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.5em;
}
.details-modal .details-modal-close svg {
  display: block;
}
.details-modal .details-modal-title {
  color: #111827;
  padding: 1.5em 1.5em;
  pointer-events: all;
  /* position: relative; */
  /* width: calc(100% - 4.5em); */
}
.details-modal .details-modal-title h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}
.details-modal .details-modal-content {
  border-top: 1px solid #e0e0e0;
  padding: 2em;
  pointer-events: all;
  overflow: auto;
}

.details-modal-overlay {
  transition: opacity 0.2s ease-out;
  pointer-events: none;
  background: rgba(15, 23, 42, 0.8);
  position: fixed;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
details[open] .details-modal-overlay {
  pointer-events: all;
  opacity: 0.5;
}

details summary {
  list-style: none;
}
details summary:focus {
  outline: none;
}
details summary::-webkit-details-marker {
  display: none;
}

code {
  font-family: Monaco, monospace;
  line-height: 100%;
  background-color: #2d2d2c;
  padding: 0.1em 0.4em;
  letter-spacing: -0.05em;
  word-break: normal;
  border-radius: 7px;
  color: white;
  font-weight: normal;
  font-size: 1.75rem;
  position: relative;
  top: -2px;
} 

@media screen and (max-width: 640px) {
  .button {
    bottom: 10px;
    left: 30px;
  }
  .details-modal {
    top: 100%;
    transform: translate(-50%, -10%);
    width: 30em;
    text-align: left;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}