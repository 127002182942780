.weatherContainer{
    margin-top: 110px;
    margin-left: 128px;
    margin-right: 128px;
    /* backg round: rgba(255, 255, 255, 0.25); */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    display: flex;
    gap: 24px;
    z-index: 10;
    padding: 20px;
    flex-wrap: wrap;
}

h6{
  color: #f4f3f3 !important;
}

h4{
  color: #f4f3f3 !important;
}

.secoondContainer{
    margin-left: 128px;
    margin-right: 128px;
    /* background: rgba(255, 255, 255, 0.25); */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    display: flex;
    gap: 24px;
    z-index: 10;
    padding: 20px;
}

.secondChild{
  /* width: 35%; */
  height: 100%;
  flex: 1 1 0;
  transform: translateY(46px);
}

.secoondContainerChild{
  width: 100%;
  /* height: 120px; */
  /* background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  border-radius: 20px;
  border: 3px solid rgba(52, 51, 51, 0.61);
  display: flex;
  flex-wrap: wrap;
}


.text{
  /* background: #151030; */
  display: flex;
  flex-direction: column;
  /* gap: 1px; */
  padding: 24px;
  border-radius: 20px;
  border: 3px solid rgba(52, 51, 51, 0.61);
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */

}

.forecast5{
  /* background: #151030; */
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  flex-wrap: wrap;
  gap: 12px;
  border-radius: 20px;
}

.firstChild{
  width: 75%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  border-radius: 20px;
  flex: 1 1 450px;
  /* background-color: #18122b; */
  position: relative;
}



/* ! second container */

.secoondContainerChild{
  display: flex;
  /* background: #151030; */
  gap: 5%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  /* height: 258px; */
  gap: 12px;
  /* border: 2px white solid; */
  /* background-color: #18122b; */
  /* border: 2.5px solid rgb(0, 0, 0); */
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  position: relative;
}



.day{
  /* width: 12.2%; */
  /* height: 100%; */
  padding: 10px 0; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
    /* background-color: #343334; */
  /* background-color: #343334; */
  /* background: #ffffff; */
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to left, #353085a5, #6dd4fa, #D4F2F2); */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* border: 0.5px solid rgb(0, 0, 0); */
  text-align: center;
  border-radius: 18px;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);
  flex: 1 1 100px;
}

.day3Hour{
  /* width: 12.2%; */
  /* height: 100%; */
  padding: 10px 0; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
    /* background-color: #343334; */
    /* background: #000000; */
    /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to left, #353085a5, #6dd4fa, #D4F2F2); */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: center;
    border-radius: 18px;
    box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
    border: 2.5px solid rgba(182, 184, 184, 0.848);
    flex: 1 1 100px;
}


.todayWeather{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: #151030; */
  height: 400px;
  padding: 0;
  gap: 32px;
    /* box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00; */
  border-radius: 20px;
  /* background-color: #18122b; */
  border: 3px solid rgba(52, 51, 51, 0.61);
  position: relative;
}


.inf{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wind{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.windS{
  display: flex;
  gap: 30px;
}

.hum{
  display: flex;
  gap: 30px;
}

@media screen and (max-width:600px) {
  .weatherContainer{
    margin: 82px 0 0 0;
  }
  .secoondContainer{
    margin: 0 0;
  }
}


.icon {
  position: relative;
  display: inline-block;
  width: 12em;
  height: 10em;
  font-size: 1em; /* control icon size here */
  background-color: currentColor;
}