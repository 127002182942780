/* *{
  color: #0b0e21;
} */

div {
  box-sizing: border-box;
}

.quiz {
  
  
  margin: 0 120px;
  margin-top: 230px;
}

.quiz .div{
  /* background: #151030; */
  /* z-index: 10; */
  /* background-color: #18122b; */
  border: 3px solid rgba(52, 51, 51, 0.61);
  position: relative;
  border-radius: 16px;
  padding: 17px;
}

.question {
  color: #f4f3f3;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);
  text-align: center;
  padding: 18px;
  min-height: 60px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.resultss {
  margin: 0 auto;
  width: 50%;
  border: 3px solid rgba(52, 51, 51, 0.61);
  flex: 1 1 450px;
  height: 300px;
  text-align: center;
  border-radius: 20px;
  
}


.congratulations {
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  text-align: center;
  border-radius: 50px;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);
  width: 240px;
  font-size: 18px;
  padding: 8px ;
  text-transform: uppercase;
  cursor: pointer;

}

.results-info {
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 18px;
}

.answer {
  background: #ffffff;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  text-align: center;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);
  border-radius: 36px;
  min-height: 50px;
  cursor: pointer;
  margin: 8px;
  margin-bottom: 20px;
  display: flex;
  flex: 1 0 130px;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.answer-letter {
  font-size: 30px;
  background-color: #0084da;
  border-bottom-left-radius: 30px ;
  border-top-left-radius: 30px ;
  color: #fff;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-text {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #f4f3f3;
}

.correct-answer {
  border: 2px solid #1ea55b;
}

.correct-answer .answer-letter {
  background: #1ea55b;
}

.wrong-answer {
  border: 2px solid #dc0a0a;
}

.wrong-answer .answer-letter {
  background: #dc0a0a;
}

.next-button {
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  text-align: center;
  border-radius: 50px;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);
  width: 240px;
  height: px;
  font-size: 18px;
  padding: 8px ;
  text-transform: uppercase;
  cursor: pointer;

}

.score {
  /* width: 500px; */
  margin: 0 auto;
  height: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #0084da; */
  margin-left: auto !important;
  margin-right: auto !important;

}

.disabled-answer {
  pointer-events: none;
}

ion-icon{
  font-size: 24px;
}

.wrong-score{
  background-color:red !important;
  border-radius: 50%
}

.correct-score{
  background-color:rgb(26, 126, 124);
  border-radius: 50%
}

.padding{
  margin: 124px;
}

@media only screen and (max-width: 600px) {
  .quiz{
    margin: 0px 20px;
  margin-top: 100px;

  }
  .padding{
    margin: 120px 50px;
  }
  .question {
    font-size: 18px;
  }
  .answer-text {
    margin-left: 100px;
  }
  .resultss {
    margin: 0 auto;
    width: 95%;
  }
  .answer {
    margin: 4px;
  }
}
