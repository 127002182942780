.game {
  position: relative;
  display: flex;
  width: 70vmin;
  height: 70vmin;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  transition: all 1s ease-out;
}
.game.rotated {
  transform: rotateY(180deg);
}
.game .line {
  position: absolute;
  width: 76%;
  height: 76%;
  transform: translateZ(6vmin) translateY(-60px);
  top: 12%;
  left: 12%;
  pointer-events: none;
  z-index: 1;
}
.game .line.visible path {
  stroke-dashoffset: 0px;
}
.game .line path {
  stroke-width: 8px;
  stroke-dashoffset: 141.42px;
  stroke-dasharray: 141.42px;
  stroke: #da2714;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.game .board {
  display: flex;
  flex-direction: column;
  flex: 1;
  transform-style: preserve-3d;
  transform: translateY(-60px);
}
.game .board .row {
  display: flex;
  flex: 1;
  margin-bottom: 2%;
  justify-content: space-between;
  transform-style: preserve-3d;
}
.game .board .row:last-child {
  margin-bottom: 0;
}

.results {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: translateZ(-6vmin) rotateY(-180deg) translateY(-60px);
  background-color: white;
  backface-visibility: hidden;
  transform-origin: bottom;
}
.results .message {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10vmin;
}
.results .message .symbol {
  display: flex;
  height: 35vmin;
}
.results .message .symbol svg {
  flex: 1;
}
.results .message .text {
  font-size: 32px;
  text-transform: uppercase;
  flex: 0 0 10vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}
.results .replay {
  height: 15vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34495e;
  color: white;
  font-size: 5vmin;
  text-transform: uppercase;
  cursor: pointer;
}
.results .replay:hover {
  background-color: rgba(52, 73, 94, 0.7);
}

.pawn path {
  stroke: #34495e;
  stroke-dasharray: 301.635;
  stroke-dashoffset: 0;
  fill: none;
  stroke-width: 16px;
}

.cube {
  flex: 0 0 32%;
  position: relative;
  transform: rotateY(0deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
  transform-style: preserve-3d;
}
.cube.rotated {
  transform: rotateY(180deg);
}
.cube div {
  position: absolute;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 3px solid rgba(228, 223, 223, 0.818);
  width: 100%;
  height: 100%;
}
.cube div svg {
  width: 80%;
  height: 80%;
}
.cube div svg path {
  stroke: #f4f3f3;
  stroke-dasharray: 301.635;
  stroke-dashoffset: 0;
  fill: none;
  stroke-width: 16px;
  transition: stroke-dashoffset 1.225s cubic-bezier(0.4, 0, 0.2, 1);
}
.cube div.back {
  transform: translateZ(-5vmin);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20vmin;
}
.cube div.front {
  transform: translateZ(5vmin) rotateY(180deg);
}
.cube div.right {
  transform-origin: right;
  transform: translateZ(5vmin) rotateY(270deg);
  width: 10vmin;
  right: 0;
  background-color: #34495e;
}
.cube div.left {
  transform-origin: left;
  transform: translateZ(5vmin) rotateY(90deg);
  width: 10vmin;
  background-color: #34495e;
}
.cube div.bottom {
  transform-origin: bottom;
  transform: translateZ(5vmin) rotateX(90deg);
  height: 10vmin;
  bottom: 0;
  background-color: #34495e;
}
.cube div.top {
  transform-origin: top;
  transform: translateZ(5vmin) rotateX(270deg);
  height: 10vmin;
  background-color: #34495e;
}