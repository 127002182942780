*{
  font-family: 'Cairo', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, #8f8b7c 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
  background-blend-mode: normal, lighten, soft-light; */
  /* background-image: linear-gradient(to right, #868f96 0%, #596164 100%); */
  /* background-image: linear-gradient(-20deg, #616161 0%, #9bc5c3 100%); */
  background-image: linear-gradient(to right, #575353cb 0%, #777b7a 15%, #468b9c 40%, #575353cb 75%,  #497884 100%);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  /* background: url(./Images/93680.jpg) bottom; */
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -9;
}

/* .active{
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
} */

.link {

  width: 58px;
  height: 58px;
  border-radius: 50%;
}



.active {
  /* border: 9px solid black; */
  /* height: 66px !important;
  width: 66px !important; */
  color:black
}

/* !cursor */

.cursor {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim .5s infinite alternate;
  pointer-events: none;
  z-index: 99999999;
}

.cursor::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 8px solid gray;
  border-radius: 50%;
  opacity: .5;
  top: 0px;
  left: 0px;
  animation: cursorAnim2 .5s infinite alternate;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.7);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.4);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(3);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.expand {
  animation: cursorAnim3 .5s forwards;
  border: 1px solid red;
}

/* ! home page */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  scroll-behavior: smooth;
  /* color-scheme: dark; */
}

.hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
}

.black-gradient {
  background: #000000;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #434343,
      #000000);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #434343,
      #000000);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(-90deg,
      #804dee 0%,
      rgba(60, 51, 80, 0) 100%);
}

.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}

.orange-text-gradient {
  background: #f12711;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top,
      #f12711,
      #f5af19);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top,
      #f12711,
      #f5af19);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text-gradient {
  background: #11998e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top,
      #11998e,
      #38ef7d);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top,
      #11998e,
      #38ef7d);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
  /* background: -webkit-linear-gradient(#eee, #333); */
  background: #56ccf2;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top,
      #2f80ed,
      #56ccf2);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top,
      #2f80ed,
      #56ccf2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: #ec008c;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top,
      #ec008c,
      #fc6767);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top,
      #ec008c,
      #fc6767);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
