.formL {
  --input-focus: #2d8cf0;
  --font-color: #fefefe;
  --font-color-sub: #ffffff;
  --bg-color: #11111100;
  --main-color: #fefefe;
  padding: 20px;
  background: var(--bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  border: 3px solid rgba(52, 51, 51, 0.61);
}

.title {
  color: var(--font-color);
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 25px;
}

.title span {
  color: var(--font-color-sub);
  font-weight: 600;
  font-size: 17px;
}

.input {
  height: 40px;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  border: 3px solid rgba(52, 51, 51, 0.61);
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  padding: 5px 10px;
  outline: none;
}

.input::placeholder {
  color: var(--font-color-sub);
  opacity: 0.8;
}

.input:focus {
  border: 2px solid var(--input-focus);
}

.login-with {
  display: flex;
  gap: 20px;
}

.button-log {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  color: var(--font-color);
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--main-color);
}

.button-log:active, .button-confirm:active {
  box-shadow: 0px 0px var(--main-color);
  transform: translate(3px, 3px);
}

.button-confirm {
  margin: 50px auto 0 auto;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  font-size: 17px;
  font-weight: 600;
  color: var(--font-color);
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .padding2{
    margin: 120px 0px;
  }
  .input{
    width: 140px;
  }
}

.button-sonuc{
    min-width: 70px;
    height: 20px;
    font-size: 14px;
    /* font-weight: 500; */
    transition: 0.8s;
    background-size: 280% auto;
    background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
    box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
    border: 2.5px solid rgba(177, 171, 171, 0.818);
    /* text-align: center; */
    color: #030303;
}