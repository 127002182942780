.sideBar {
  /* width: 100px; */
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  z-index: 99;
  top: 0;
}

.bar {
  width: 100px;
  height: 80%;
  background: #227980;
  backdrop-filter: blur( 11.5px );
  -webkit-backdrop-filter: blur( 11.5px );
  border-radius: 10px;
  box-shadow: 4px 4px 8px #2826263d, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(182, 184, 184, 0.848);
  display: flex;
  flex-direction: column;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  justify-content: space-between;
  padding: 80px 0;
  align-items: center;
}

/* .sideBar::after{
  position: absolute;
  bottom: 0;
  content: 's';
  text-align: center;
  width: 100px;
  height: 30px;
  border-top-left-radius: 45px;
  
  background: rgba( 255, 255, 255, 0.25 );
} */

/*
.sideBar::before{
  content: 's';
  width: 100px;
  height: 30px;
  border-top-left-radius: 25px;
  background-color: rgb(0, 0, 0);
} */

/* !middle */
.middle{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 72px;
}

a{
  text-decoration: none !important;
}

.barItem{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  /* gap: 30px; */
  cursor: pointer;
  transition: 0.3s;
}

.box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .sideBar {
    width: 100%;
    height: 100px;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-top: -90px;
    bottom: 0;
    z-index: 9;
  }
  
  .bar {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    /* background: linear-gradient(to bottom,#ffffffc3,#ffffffa9); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    position: fixed;
    bottom: 0px;
    padding:45px 0 45px 0;
  }
  .middle{
    flex-direction: row;
    gap: 60px;
  }
}

.iconName{
  transform: translateY(-20px);
  color: #f1ebeb
}

.gamesContainer{
  opacity: 1;
  position: absolute;
  transform: translateY(900px);
  top: 0;
  background: #935854;
  backdrop-filter: blur( 11.5px );
  -webkit-backdrop-filter: blur( 11.5px );
  border-radius: 10px;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(182, 184, 184, 0.848);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  /* padding: 80px 0; */
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

.appsContainer{
  opacity: 1;
  position: absolute;
  transform: translateY(900px);
  top: 0;
  background: #935854;
  backdrop-filter: blur( 11.5px );
  -webkit-backdrop-filter: blur( 11.5px );
  border-radius: 10px;
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(182, 184, 184, 0.848);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  /* padding: 80px 0; */
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

.slideDown{
  transform: translateY(0px) !important;
}

.slideUp{
  transform: translateY(0px) !important;
}

.exitB{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  width: 100%;
  font-size: 40px;
  color: #efefef;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 25px;
  /* border: 1px solid  #ede7e6; */
  height: 45px;
}

@media only screen and (max-width: 600px) {
  .appsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px !important;
  }
  .gamesContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px !important;
  }
  .exitB{
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 40px;
    font-size: 40px;
    color: rgb(255, 255, 255);
    background: #efeded00;
    border: 0px solid #efeded00 !important;
    height: 30px;
  }
  
}

