/* .containerA {
  margin-top: 128px;
  margin-left: 128px;
  margin-right: 128px; 
  gap: 24px;
  z-index: 10;
}
 */

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}

/* section{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110vh;
  background: linear-gradient(to bottom,#f1f4f9,#dff1ff);
} */

section .color{
  position: absolute;
  filter: blur(150px);
}

section .color:nth-child(1){
  top: -350px;
  width: 500px;
  height: 500px;
  left: -165px;
  background: #ff359b;
}


section .color:nth-child(2){
  bottom: -150px;
  left: -136px;
  width: 400px;
  height: 400px;
  background: #fffd87;
}


section .color:nth-child(3){
  bottom: 180px;
  right: 200px;
  width: 300px;
  height: 300px;
  background: #00d2ff;
}


.boxC{
  position: relative;
  width: 100%;
  height: 100%;
  top: 116px;
  display: flex;
  justify-content: center;
}

.square{
  position: absolute;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.281);
  border-radius: 10px;
  animation: animate 8s linear infinite;
  animation-delay: calc(-1s * var(--i));
}

@keyframes animate {
  0%,100%{
      transform: translateY(-40px);
  }
  50%{
      transform: translateY(40px);
  }
}

.square:nth-child(1){
  top: -90px;
  right: 100px;
  width: 100px;
  height: 100px;
  /* z-index: 2; */
}

.square:nth-child(2){
  top: 10px;
  left: 20px;
  width: 120px;
  height: 120px;
}

.square:nth-child(3){
  bottom: 140px;
  right: 30px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.square:nth-child(4){
  bottom: -55px;
  right: 50%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* z-index: 2; */
}

.container{
  position: relative;
  display: flex;
  width: 87%;
  height: 550px;
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 20px;
  background-color: #18122b;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  background-color: #797777;
  /*  */
  /* -webkit-backdrop-filter: blur( 4px ); */
  border-radius: 10px;
  border: 3px solid rgba(205, 195, 195, 0.61);
  margin-top: -170px;
}

.containerV{
position: relative;
/* display: flex; */
width: 20%;
text-align: center;
height: 120px;
background: rgba(20, 168, 222, 0.25);
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.281);
}

.visits{
display: flex;
justify-content: center;
gap: 150px;
margin-bottom: 40px;
flex-wrap: wrap;
margin-top: -170px;

}

#a{
  overflow: hidden;
  overflow-y: scroll;
  height: 450px;
}

.form{
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: row-reverse;
  /* left: 0; */
  justify-content: center;
  align-items: center;
  padding: 12px;
}

form{
  display: flex;
  width: 94%;
}

#record{
  width: 3%;
  height: 50px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border: none;
  background-color: #E2E2E2;
  /* background: -webkit-linear-gradient(to left, #FFFFFF, #6dd4fab9, #297fb9b2);  Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #FFFFFF, #6dd4fa8d, #3394d49d); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; */
}

.inputBox{
  width: 100%;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}


.input-group {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}

.inputs {
  height: 50px;
  width: 90%;
  padding: 0 1rem;
  color: #ffffff;
  font-size: 16px; 
  background: #E2E2E2;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  /* border-bottom-left-radius: 25px;
  border-top-left-radius: 25px; */
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.button--submit {
  min-height: 52px;
  padding: .5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #134a89 !important;
  color: #fff !important;
  font-size: 19px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}

.button--submit:hover {
  background-color: #5e5dcd;
}

.input:focus, .input:focus-visible {
  border-color: #3898EC;
  outline: none;
}

#a{
  width: 100%;
}


@media screen and (max-width: 700px) {
  .containerA {
    margin: 142px 0;
    margin-bottom: 240px !important;
    padding: 10px;
  }
  .form{
    left: 0;
  }

  form{
    width: 85%;
  }

  #record{
    width: 15%;
  
  }
  
}

.icon{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.sa{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}