h5 {
  font-size: 92px;
  text-align: center;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  color: #E2E2E2 !important;
}


.containerA {
  margin-top: 220px;
  margin-left: 128px;
  margin-right: 128px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 10;
}


.statusBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 40px;
  padding: 1px 98px;
  border-radius: 20px;
  border: 3px solid rgba(52, 51, 51, 0.61);
  position: relative;
  border-radius: 16px;
}

.statusBar .btnAdress {
  flex: 1 0 20px;
  padding: 8px;
}

.statusBar .status {
  flex: 1 0 100px;
}

.timeBar {
  background: #00000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 64px;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0px;
  height: 80px;
  background-color: #00000000;
  position: relative;
  border-radius: 16px;
}


.allContainer {
  width: 45%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #3630857c, #498196cc, #7a95a3);
  box-shadow: 4px 4px 8px #282626, -4px -4px 15px #fdfdfd00;
  border: 2.5px solid rgba(177, 171, 171, 0.818);  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
}

.salatBar {

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 98px;
  flex-direction: column;
  gap: 24px;
  border-radius: 20px;
  border: 3px solid rgba(52, 51, 51, 0.61);
  position: relative;
}


.salatBar .father {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

}

/* ! mobile */
@media only screen and (max-width: 600px) {
  .containerA {
    margin-left: 36px;
    margin-right: 36px;
    gap: 16px;
  }

  .timeBar {
    padding: 0 0px;
  }

  .salatBar{
    padding: 22px 12px;
  }

  .salatBar .father {
    flex-direction: column;
  }

  .fjr {
    width: 90%;
  }

  .shruq {
    width: 90%;
  }

  .dhr {
    width: 90%;
  }

  .asr {
    width: 90%;
  }

  .magriph {
    width: 90%;
  }

  .isha {
    width: 90%;
  }
  .azanC{
    margin-top: 100px !important;
  }
}

@keyframes gradientanimation {
  0% {
    filter: hue-rotate(0) blur(0.5rem);
  }
  100% {
    filter: hue-rotate(360deg) blur(0.5rem);
  }
}