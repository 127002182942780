.center{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}


::selection {
  color: #fff;
  background-color: #7d2ae8;
}
.containers {
  padding: 2rem 7rem;
  border-radius: 14px;
  background: #c8c3c3;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  flex-direction: column;
}
.result_images {
  display: flex;
  column-gap: 7rem;
}
.containers.start .user_result {
  transform-origin: left;
  animation: userShake 0.7s ease infinite;
}
@keyframes userShake {
  50% {
    transform: rotate(10deg);
  }
}
.containers.start .cpu_result {
  transform-origin: right;
  animation: cpuShake 0.7s ease infinite;
}
@keyframes cpuShake {
  50% {
    transform: rotate(-10deg);
  }
}
.result_images img {
  width: 100px;
}
.user_result img {
  transform: rotate(90deg);
}
.cpu_result img {
  transform: rotate(-90deg) rotateY(180deg);
}
.result {
  text-align: center;
  font-size: 2rem;
  color: #e8992a;
  margin-top: 1.5rem;
}
.option_imager img {
  width: 50px;
}
.option_images {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  justify-content: space-between;
  gap: 80px;
}
.containers.start .option_images {
  pointer-events: none;
}
.option_imager {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.option_imager p {
  color: #7d2ae8;
  font-size: 1.235rem;
  margin-top: 1rem;
  pointer-events: none;
}