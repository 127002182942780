.button-name {
  align-items: center;
  appearance: none;
  background: linear-gradient(to right, #353085a5, #6dd4fa, #7a95a3);
  border: 2.5px solid rgb(205, 217, 225);
  text-align: center;
  border-radius: 18px;
  box-shadow: 4px 4px 8px #282626d7, -4px -4px 15px #fdfdfd83;
  padding: 1px 0;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 15px;
  width: 122px;
  color: #00266C
 }

 .button-name:hover {
  box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px, rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #080808 0 -3px 0 inset;
  transform: translateY(-2px);
 }
 
 .button-name:active {
  box-shadow: #121212 0 3px 7px inset;
  transform: translateY(2px);
 }

 @media only screen and (max-width: 600px) {
  .button-name{
    width: 100px;
    height: 36px;
    font-size: 12px;
  }
}