.header{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 76px;
  width: 100%;
  background-image: linear-gradient(to right, #878080cb 0%, #939a98 15%, #56a8bd 40%, #948c8ccb 75%,  #57909e 100%);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.272);
  backdrop-filter: blur( 11.5px );
  border-radius: 10px;
  /* border: 0.2px solid rgba( 255, 255, 255, 0.18 ); */
  position: fixed;
  z-index: 999;
  top: 0;
}

.socialIcons{
  display: flex;
}

.socialIcons .barItem{
  margin: 32px;
}

.logo{
  /* background-color: rgb(0, 0, 0); */
  width: 72px;
  height: 72px;
  text-align: center;
  border-radius: 50%;
}

.logo h3{
  /* color: aliceblue !important; */
}



p h3{
  color: #f5f2f2 !important;
  font-size: 36px;
}

@media screen and (max-width: 600px) {
  .header{
    /* background: rgba(177, 177, 177, 0.664); */
  }
  .socialIcons .barItem{
    margin: 10px;
  }
  .textS{
    text-align: center;
  }
  p h3{
    font-size: 24px;
  }
}

