/* ul {
  margin:0;
  padding:0 !important;
  display:flex;
} */

ul li {
  list-style: none;
}

.ul li a {
  position: relative;
  width:66px;
  height:66px;
  display:block;
  text-align:center;
  margin:0 10px;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-decoration:none;
  /* box-shadow: 0 10px 15px rgba(172, 167, 167, 0.3); */

  transition: .5s;
}


ul li a .fab {
  widht: 100%;
  height:100%;
  display:block;
  background: #ffffff00;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to left, #473e66, #6dd4fab9, #1b3358); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #473e66, #6dd4fa8d, #1b3358); */
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size:24px;
  color: #ffffff;
  transition: .5s;
}

ul li a .fa-solid {
  widht: 100%;
  height:100%;
  display:block;
  background: #ffffff00;
  /* fallback for old browsers */
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size:24px;
  color: #ffffff;
  transition: .5s;
}

